import React from 'react';

import { dreamCafe, maileBeauty } from '@constants/config';
import DenimDreamLogo from '@icons/DenimDreamLogo';
import DreamCafeLogo from '@icons/DreamCafeLogo2';
import MaileBeautyLogo from '@icons/maileBeautyLogo';

import AppBarStyles from './styles';

const AppBarLogo: React.FunctionComponent<{}> = () => {
  const classes = AppBarStyles();

  if(dreamCafe){
    return <DreamCafeLogo className={classes.logo} />;
  }
  if(maileBeauty){
    return <MaileBeautyLogo className={classes.logo} />;
  }
  return <DenimDreamLogo className={classes.logo} />;
};

export default AppBarLogo;
